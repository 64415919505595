import React from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { actions as localizationSliceActions } from '../data/localization/logic';
import type { StoreState } from '../data/store';
import { EventBusProvider } from './event-bus';

interface Props {
  Router?: React.ComponentType;
}
const ContextProvider: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { Router = BrowserRouter } = props;
  const { locale } = useSelector((s: StoreState) => s['localization']);

  React.useEffect(() => {
    dispatch(localizationSliceActions.fetchMessages({ locale }));
  }, [locale]);

  const noop = React.useCallback(() => {
    ////
  }, []);

  return (
    <EventBusProvider>
      <Router>
        <IntlProvider locale={locale} defaultLocale="en" onError={noop}>
          {props.children}
        </IntlProvider>
      </Router>
    </EventBusProvider>
  );
};
export default ContextProvider;
