import React from 'react';
import { Provider } from 'react-redux';
import type { Store } from 'redux';

interface Props {
  // allow injecting for tests
  reduxStore: Store;
}
const ReduxProvider: React.FC<Props> = (props) => {
  const { reduxStore } = props;
  return <Provider store={reduxStore}>{props.children}</Provider>;
};
export default ReduxProvider;
