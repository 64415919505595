import React, { createContext } from 'react';
import { useSelector } from 'react-redux';
import Routing from '../../components/Routing/Routing';
import type { StoreState } from '../../data/store';

export const Locale = createContext({
  locale: 'en-ca',
});

const AppContainer = () => {
  const locale = useSelector((s: StoreState) => s['localization'].locale);
  return (
    <Locale.Provider
      value={{
        locale,
      }}
    >
      <Routing />
    </Locale.Provider>
  );
};
export default AppContainer;
