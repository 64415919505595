import { generatePath as generatePathRR } from 'react-router-dom';
import type { Empty } from '@owl-frontend/components';
import { CombineObjectType, enumSet } from '@owl-lib/type-util';
import type { GeneratePathParam } from '../../helpers/route-types';

export enum RouteDefs {
  Main = '/',
}

export const routeSet = enumSet(RouteDefs);

// example
// type LoginRouteParams = ExtractRouteParams<RootRoute.Login>

type GeneratePathRest<Params> = Empty extends Params
  ? [params?: Params]
  : [params: Params];
type TypedGeneratePath = <R extends string>(
  path: R,
  ...rest: GeneratePathRest<CombineObjectType<GeneratePathParam<R>>>
) => string;
export const generatePath = generatePathRR as TypedGeneratePath;
