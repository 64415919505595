import React, { useMemo } from 'react';
import Icon, { InboxOutlined, LogoutOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { Link, Outlet } from 'react-router-dom';
import { Dropdown, Layout, Menu, Modal } from 'antd';
import { Button } from '@owl-frontend/components';
import OwlLogo from '../../assets/logo-26.svg';
import styles from './MainContainer.module.scss';

const MainContainer = () => {
  const [expanded, setExpanded] = React.useState(false);
  const dropdownItems = useMemo(() => {
    const result = [
      {
        key: 'logout',
        icon: <LogoutOutlined />,
        label: (
          <Link
            data-testid="logout-btn"
            to="#"
            onClick={() => {
              Modal.confirm({
                content: 'You sure you are signing out?',
                // onOk: () => {
                //   dispatch(sessionSliceActions.logout());
                // },
              });
            }}
          >
            Logout
          </Link>
        ),
      },
    ];
    return result;
  }, []);
  return (
    <Layout className={styles.mainContainer} hasSider>
      <Layout.Sider
        className={styles.sider}
        collapsible
        defaultCollapsed
        collapsed={!expanded}
        onCollapse={(value) => setExpanded(!value)}
      >
        <div>
          <Icon
            className={clsx(styles.owlIcon, expanded && styles.owlIconExpanded)}
            component={OwlLogo}
          />
          <Menu
            theme="dark"
            selectedKeys={[location.pathname.split('/')[1]]}
            items={[
              {
                key: 'claims',
                icon: <InboxOutlined />,
                label: 'Claims',
              },
            ]}
            selectable
          />
        </div>
        <Dropdown
          className={clsx(
            styles.avatarContainer,
            expanded && styles.avatarContainerOpened
          )}
          placement="topRight"
          menu={{ selectable: false, items: dropdownItems }}
        >
          <Button
            className={styles.avatarButton}
            data-testid="user-dropdown"
            shape="circle"
            type="text"
          >
            User
          </Button>
        </Dropdown>
      </Layout.Sider>
      <Layout>
        Claims
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default MainContainer;
