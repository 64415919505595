import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MainContainer from '../MainContainer/MainContainer';
import { RouteDefs } from './routes';

// We are using react-router v6. See the documentation:
// https://github.com/remix-run/react-router/blob/dev/docs/api-reference.md
// and differences here:
// https://github.com/remix-run/react-router/blob/dev/docs/advanced-guides/migrating-5-to-6.md
// See https://github.com/remix-run/react-router/releases/tag/v6.0.0-beta.4 for some info on routes

const Routing = () => (
  <Routes>
    <Route path={RouteDefs.Main} element={<MainContainer />}></Route>
  </Routes>
);

export default Routing;
